.container-what-we-do {
    width:100%;
    height:800px;
    position:relative
}
.background-image {
    width:100%;
    height:100%;
    object-fit: cover;
    inset: -64px 0px !important;
}
.title-whatwedo{
    position: relative;
    width: fit-content;
}
.title-whatwedo img{
    position: absolute;
    width: 100%;
    bottom: -15px;
}
.layout{
    position:absolute;
    top:0;
    color:white;
    width:100%;
    height:100%;
    text-align:left;
    padding:15px 3.75rem;
    box-sizing: border-box;
}
.layout h6 {
    font-size: 24px;
    font-weight: 500;
    color: #E7E4DC;
    margin:20px 0;
}
.content-container {
    display:flex;
    justify-content:space-between;
    margin-top: 40px
}

.unorderd-list{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;
    margin: 0;
    position: relative;
}
.unorderd-list .list-container{
    width: calc(100%/3);
    height: 190px;
    border:0.5px solid #D9D9D9;
    box-sizing: border-box;
    position: relative;
}
.unorderd-list .selected-container{
    width: calc(100%/3);
    height: 190px;
    border:none;
    box-sizing: border-box;
    position: relative;
}
.unorderd-list .list-container:hover {
    cursor: pointer;
}
.unorderd-list .faded-container{
    width: calc(100%/3);
    height: 190px;
    border:none;
    box-sizing: border-box;
}
.unorderd-list .list {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.unorderd-list .selectedlist {
    width: 100%;
    height: 100%;
    /* -webkit-clip-path: polygon(0% 75%, 20% 100%, 100% 100%, 100% 0%, 0% 0%);
    clip-path: polygon(0% 75%, 20% 100%, 100% 100%, 100% 0%, 0% 0%); */
    background: #9DCB3B;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}
.small-triangle {
    position: absolute;
    transform: rotate(225deg);
    left: -21px;
    bottom: -4px;
        width: 65px;
        height: 32px;
        background-color: #9DCB3B;
        clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}
.unorderd-list .selectedlist p{
    color: #192057;
text-align: center;
font-size: 16px;
font-family: Poppins;
font-weight: 600;
}
.right-container {
    width:48%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.left-container {
    width: 48%;
    display: flex;
    flex-direction: column;
}
.left-container .heading-h2 {
    height: 20%;
}
.left-container .icons {
    height: 30%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.whatwedo-icon-container {
    width:100px;
    height: 100px;
}
.left-container .icons img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.left-container .leftContent {
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
/* .left-container div {
    flex: 1;
} */
.left-container h2 {
    font-size: 42px;
    font-weight: 500;
    color: #E7E4DC;
    margin: 0px;
}
.left-container .sub-text {
    font-size: 16px;
    font-weight: 500;
    color: #E7E4DC;
}
.left-container .connect-with-us p{
    font-size: 1.2rem;
    font-weight:600;
    color: #fff;
    margin-bottom: 5px;
}
.connect-with-us .whatwedo-bar {
    width: 80%;
    height: 5px;
    border-radius: 8px;
    background-color: white;
    overflow: hidden;
    margin-top: 8px;
}
.connect-with-us .whatwedo-bar .green-bar {
    width: 40%;
    height: 5px;
    background-color: #9DCB3B;
}
.unorderd-list li p {
    padding: 20px;
}
.mini-arrow {
    position: absolute;
    transform: translate(0, -50%);
    top: 50%;
    left: 10px;
}

@media only screen and (max-width:77rem){
    .layout{
        padding: 15px 26px;
    }
    .background-image{
    inset: -160px 0px !important;
    }
    .left-container h2 {
        font-size: 1.8125rem;
    }
    .layout h6 {
        font-size: 1.125rem;
    }
    .left-container .sub-text{
        font-size: 0.75rem;
    }
    .left-container .connect-with-us p{
        font-size: 0.75rem;
    }
    .unorderd-list .selectedlist p{
        font-size: 0.75rem;
    }
    .unorderd-list li p {
        font-size: 0.75rem;
    }
    .unorderd-list .list, .unorderd-list .list-container,.unorderd-list .selected-container,.unorderd-list .faded-container{
        height: 8.62rem;
    }
}

@media only screen and (max-width: 50rem) {
    .left-container .leftContent { 
        height: 50%;
    }
    .left-container .icons { 
        height: 25%;
    }
    .left-container .heading-h2 {
        height: 25%;
    }
    .whatwedo-icon-container{
        height: 60px;
    }
    .layout{
        padding: 15px 20px;
    }
    .unorderd-list .list {
        height: 100%;
    }
    .background-image{
        inset: 2px 0px !important;
    }
    .container-what-we-do {
        height: 50rem;
    }
    .content-container {
        flex-direction: column;
        height: 86%;
    }
    .content-container div{
        width: 100%;
        text-align: center;
        align-items: center !important;
    }
    .left-container h2 {
        font-size: 1.375rem;
    }
    .left-container .sub-text{
        font-size: 0.6875rem;
    }
    .left-container .connect-with-us p{
        font-size: 0.6875rem;
    }
    .unorderd-list .selectedlist p{
        font-size: 0.43506rem;
    }
    .unorderd-list li p {
        font-size: 0.43506rem;
    }
    .connect-with-us .whatwedo-bar {
        width: 100%;
    }
    .connect-with-us .whatwedo-bar .green-bar {
        width: 50%;
    }
    .unorderd-list .list-container {
        width: 105px;
        height: 100px;
    }
    .unorderd-list .selectedlist {
        width: 100%;
        height: 100px;
        position: relative;
    }
    .unorderd-list .faded-container {
        width: 105px;
        height: 100px;
    }
    .unorderd-list .selected-container {
        height: 100%;
        width: 105px;
    }
    .left-container img{
        width: 5rem;
    }
    .left-container h2{
        margin-bottom: 20px;
    }
    
}