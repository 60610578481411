.industriesMain {
  display: flex;
  flex-wrap: wrap;
  padding: 53px 3.75rem;
  overflow: hidden;
}

.headingText {
  font-size: 24px;
  font-weight: 500;
  line-height: 57.706px;
  position: relative;
}
.headingText img{
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

.subTitleText {
  font-size: 37px;
  font-style: italic;
  font-weight: 275;
  line-height: 143.5%;
}

.subTitleTextBold {
  font-weight: 500;
  font-size: 39px;
  font-style: medium;
  line-height: 143.5%;
}

.industries-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  /* Additional styles for the container if needed */
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 20px 0px;
  margin-bottom: 5px;
  /* Additional styles for the rows if needed */
}

.column {
  flex: 1;
  flex-wrap: wrap;
  justify-content: end;
  /* Additional styles for the columns if needed */
}

.industriesSelctor {
  padding: 5px 22px;
  justify-content: center;
  align-items: center;
  margin: 4px 5px;
  border-radius: 29px;
  font-size: 18px;
  font-weight: 400;
  /* text-transform: lowercase; */
}

.active {
  background: #9dcb3b;
}

.inactive {
  border: 1px solid #192057;
}

.contentRow {
  display: flex;
  flex-direction: row-reverse;
}

.IndustriesPageImage {
 width: 100%;
}

.subSectionTitleHeading {
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  text-align: left;
  word-wrap: normal;
}

.subSectionDescription {
  color: #192057;
  font-size: 16px;
  text-align: left;
}

.subTitle {
  text-align: left;
}

.subSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 25px;
  justify-content: flex-start;
  margin-left: 35px;
  margin-right: 10px;
  margin-top: 25px;
}

.secondRow {
  padding-left: 30px;
  margin-bottom: 50px;
}

.arrowIcon {
  padding: 8px;
}

.arrows {
  position: relative;
  top: 0;
  left: 94.2%;
}

.flipArrow {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.bar {
  margin-bottom: 30px;
  background-color: #192057e0;
  height: 2px;
}

.bar .bar_row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: transparent;
  position: relative;
 
}

.bar .dot {
  border-radius: 100%;
  z-index: 1;
  position: relative;
  top: 1px;
  transform: translate(0%, -50%);
}

.not-selected {
  background-color: #192057e0;
  width: 6px;
  height: 6px;
}


.selected {
  background: #9dcb3b; 
  width: 13px;
  height: 13px;
}

.brandsRow {
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap:wrap;
  justify-content: center;
}
.partnerBrands{
  border-radius: 39px;
  background: linear-gradient(102deg, rgba(217, 217, 217, 0.50) 0%, rgba(217, 217, 217, 0.40) 100%);
  align-items: center;
  padding: 10px 20px ;
  width: 70%;
  overflow: hidden;
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
}

@keyframes slide {
	from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.partnerBrands div{
  display: flex;
    align-items: center;
		animation: 5s slide infinite linear;
    justify-content: space-around;
}
.partnerBrands div img{
  margin: 0 20px;
    height: 100%;
}
.partnerBrands:hover div{
  animation-play-state: paused;
}


@media only screen and (max-width: 77rem) {
  .secondRow{
    padding-left: 0;
  }
  
  .subSection{
    margin-left: 0;
  }
  .headingText{
    font-size: 1.125rem;
  }
  .subTitleText{
    font-size: 1.75rem;
  }
  .subTitleTextBold{
    font-size: 1.875rem;
  }
  .industriesSelctor{
    font-size: 0.85rem;
  }
  .subSectionTitleHeading{
    font-size: 1.8125rem;
  }
  .subSectionDescription{
    font-size: 0.75rem;
  }
  .IndustriesPageImage{
    height: 184px;
    width: 22rem;
  }
}

@media only screen and (max-width: 702px) {
  .headingText img{
    bottom: 6px;
  }
  .industriesMain {
    padding: 0px;
    overflow-x: hidden;
    margin: 20px;
  }
  .firstRow .column{
    margin-bottom: 1.3rem;
  }
  .contentRow{
    flex-direction: row;
  }

  .row {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0px 0px;
  }
  .subTitle {
    padding: 0px 35px 25px 0px;
  }

  .subTitleText {
    font-size: 16px;
    font-style: italic;
    font-weight: 275;
    line-height: 20.72px;
  }

  .subTitleTextBold {
    font-size: 18px;
    font-style: medium;
    line-height: 23.72px;
  }

  .industriesSelctor {
    padding: 4px 8px;
    margin: 2px 3px;
    border-radius: 29px;
    font-size: 11.246px;
    font-style: normal;
    line-height: normal;
  }

  .secondRow {
    padding-left: 0px;
    padding-top: 20px;
  }

  .IndustriesPageImage {
    width: 280.866px;
    height: 136.122px;
  }

  .subSectionTitleHeading {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0em;
  }

  .subSectionDescription {
    font-size: 8px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: justified;
    padding-top: 10px;
  }

  .subSection {
    padding: 5px 0px 0px;
    justify-content: center;
    align-items: center;
    margin-left: 33%;
    margin-right: 0;
  }

 
.bar {
  margin: 10px 0px;
  height: 2px;
}

.bar .dot {
 
  top: -0.5px;
}

.not-selected {
  background-color: #192057e0;
  width: 6px;
  height: 6px;
}


.selected {
  background: #9dcb3b; 
  width: 9px;
  height: 9px;
}


.arrows {
  display: flex;
  flex-direction: row;
 justify-content: space-between;
 position:static;
 left:0;
 top: 0;
 padding-top: 10px;

}

.arrowIconimg {
  width: 12.467px;
  height: 17px;
 
}

.arrowIcon {
  padding: 1px;
}

.bar .bar_row {
  top: 2px;
}

.partnerBrands{
  width: 90%;
  margin-top: 25px;
  margin-bottom: 8px;
}
.brandsImg{
  width: 50px;
}

 /* .brandsImg{
  width: 20% ;


flex-shrink: 0;
padding: 0px;
margin: 0px;
 } */

 /* .partnerBrands{
   height: inherit;
  padding: 7px 14px ;
  gap: 16px;
} */

.hideLogo{
  display: none;
}

}

/* ul.progress-bar li {
 
} */
