.training-main-container {
    padding: 3.44rem 3.88rem 2.44rem 3.88rem;
    overflow: hidden;
}

.training-main-container .title {
    text-align: left;
    font-size: 1.5rem;
    margin-bottom: 2.81rem;
    font-weight: 500;
    position: relative;
}

.training-header {
    display: flex;
    justify-content: space-between;
}

.bold {
    font-weight: bold;
}

.green {
    color: #9DCB3B;
}

.training-header .left {
    display: flex;
    flex-direction: column;
    color: #192057;
}


.training-header .left .first {
    text-align: left;
    font-size: 2.625rem;
    margin-bottom: 2rem;
    font-weight: 500;

}

.training-header .left .second {
    /* max-width: 45.25rem; */
    text-align: justify;
    font-size: 1.125rem;
    font-weight: 400;
}

.training-main-container hr {
    border: none;
    height: 2px;
    background-color: #192057;
    margin-top: 4rem;
}

.training-main-container .title-container {
    display: flex;
    justify-content: space-between;
}

.title-container img {
    display: none;
}
.title-container .underline-img {
    display: block;
    position: absolute;
    width: 100%;
}

.training-data {
    display: flex;
    flex-wrap: wrap;
    padding-top: 4.56rem;
}
.group {
    display: flex;
    margin-bottom: 4.38rem;
}

.group2 {
    display: flex;
    margin-bottom: 4.37rem;
}

.data-container {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.left {
    justify-content: start;
}

.right {
    justify-content: end;
}

.training-data .data-title {
    text-align: left;
    font-size: 1.75rem;
    font-weight: 600;
}

.training-data .data-para {
    text-align: justify;
    font-size: 1.375rem;
    font-weight: 500;
}

.bottom-right-corner {
    border-radius: 0 0 7rem 0;
}

.last-section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.last-section .note {
    color: #192057;
    font-weight: 400;
    max-width: 45.25rem;
    text-align: center;
}

.last-section .sechedules {
    margin-top: 1.5rem;
    font-weight: 500;
}

.last-section button {
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    margin-top: 1.5rem;
    background-color: #9DCB3B;
    border: 1px solid #9DCB3B;
    padding: 0.8rem 4rem;
    border-radius: 50rem;
    font-weight: 500;
    color: #192057;
    transition: all 0.16s ease-in;
}
.last-section button:hover{
    background-color: transparent;
    border:1px solid
}



/* Responsive Code */

/* For Tablet */


@media only screen and (max-width: 77rem) {
    .training-main-container .title{
        font-size: 1.125rem;
        margin-bottom: 1.9rem;
    }
    .training-header .left .first{
        font-size: 1.875rem;
    }
    .training-header .left .second {
        font-size: 0.75rem;
    }
    .training-data .data-title {
        font-size: 1.125rem;
    }
    .training-data .data-para {
        font-size: 1rem;
    }
    .last-section .note{
        font-size: 0.72813rem;
        max-width: 25rem;
    }
    .last-section button{
        font-size: 0.72813rem;
    }
    .last-section .sechedules{
        font-size: 0.72813rem;
    }
    .right img{
        width:8.5rem;
        height: 8.5rem;
    }
    .training-main-container hr{
        margin-top: 2rem;
    }
     .left img{
        width: 22rem;
        height: 12rem;
        object-fit: contain;
    }
   .right .bottom-right-corner{
        width: 24rem;
        height: 12rem;
        object-fit: contain;
    }
    
     .training-main-container {
        padding: 1.9rem 1.25rem 2.92rem 1.25rem;
    }
    .training-data {
        padding-top: 3.2rem;
    }
}

/* For Mobile */

@media only screen and (max-width: 45rem) {

    .training-main-container {
        padding: 1.9rem 1.25rem 2.92rem 1.25rem;
    }

    .training-main-container .title {
        font-size: 1rem;
        margin-bottom: 1.9rem;
    }

    .training-header .left .first {
        font-size: 1.125rem;
    
    }
    
    .training-header .left .second {
        font-size: 0.5625rem;
    }

    .title-container img {
        display: block;
    }

    .training-data {
        padding-top: 3.2rem;
    }



    .training-data img {
        width: 9.5rem;
        height: 6.3125rem;
    }
    .right .bottom-right-corner{
        width: 9.5rem;
        height: 6.3125rem;
    }

    .training-header .right {
        display: none;
    }

    .training-header .left .second {
        max-width: none;
    }

    .training-main-container hr {
        margin-top: 2.5rem;
    }

    .training-data .data-title {
        font-size: 0.625rem;
    }
    
    .training-data .data-para {
        font-size: 0.5rem;
    }

    .group {
        margin-bottom: 2.44rem;
    }

    .group2 {
        margin-bottom: 3.68rem;
    }

    .bottom-right-corner {
        border-radius: 0 0 3rem 0;
    }

    .last-section .note {
        font-size: 0.5rem;
        max-width: 25rem;
    }
    
    .last-section .sechedules {
        font-size: 0.5rem;
        margin-top: 0.6rem;
    }

    .last-section button {
        font-size: 0.5rem;
    }    
    
}


@media only screen and (max-width: 24rem) {
    
}