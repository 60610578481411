.contact-us-body {
    background-color: #E7E4DC;
    padding: 3.31rem 5.5rem 0 3.63rem;
}
.contact-title-container{
    position: relative;
    width: fit-content;
}
.contact-title-container img{
    position: absolute;
    width: 100%;
    bottom: -10px;
}
.contact-icon-container {
    display: flex;
}
.contact-icon-container img {
    margin-right: 8px;
}
.contact-left-section {
    flex: 1;
    padding-top: 60px;
}
.contact-left-section .email {
    cursor: pointer;
}
.border-right {
    border-right: 1px solid #192057;
}
.contact-left-section h6{
    font-size: 23px;
    color: #192057;
    font-weight: 600;
    margin: 0;
    margin-bottom: 5px;
}
.contact-left-section p{ 
    font-size: 14px;
    font-weight: 500;
    color: #192057;
}
.contact-right-section{
    flex: 2;
    display: flex;
    justify-content: flex-end;
}
.touch {
    font-size: 3.8125rem;
    color: #192057;
    font-style: normal;
    font-weight: 500;
    margin: 1.88rem 0;
}

.contact {
    font-size: 1.5rem;
    color: #192057;
    font-style: normal;
    font-weight: 500;
    margin: 0;
}


.f-input {
    width: 100%;
    border-radius: 1.5rem;
    margin-bottom: 1.5rem;
    padding: 1rem;
    background-color: #E7E4DC;
    border: 1px solid #192057;
    box-sizing: border-box;
    display: block;
}

.l-input {
    width: 100%;
    padding: 1rem;
    height: 10.125rem;
    border: 1px solid #192057;
    margin-bottom: 1.5rem;
    border-radius: 1.5rem;
    background-color: transparent;
    resize: none;
    box-sizing: border-box;
    display: block;
}



.contact-submit-button {
    border: none;
    background-color: transparent;
}

.contact-us-container {
    display: flex;
}
::placeholder{
    opacity: 0.6;
}
.f-input{
    color: #192057;
    font-family: poppins;
}
input:focus::placeholder {
    color: transparent;
  }
textarea:focus::placeholder {
    color: transparent;
  }

.contact-us-form {
    width: 50%;
}

.map-container {
    width: 90%;
}

.world-map {
    width: 100%;
    height: 100%;
}

.f-input::placeholder {
    font-family: poppins;
    color: #192057;
    font-size: 1rem;
}

.l-input::placeholder {
    font-family: poppins;
    color: #192057;
    font-size: 1rem;
}


/* Footer CSS */

.foot-body {
    display: flex;
    justify-content: center;
    margin: 3rem 0;
    margin-top: 0;
}

.start-content {
    width: fit-content;
}

.foot-items {
    margin: 0;
    padding: 0;
    background-color: #9DCB3B;
    display: flex;
    list-style: none;
    border-radius: 10rem;
    padding: 1.22rem 1.38rem;
    gap: 1.94rem;
}

.f-item {
    display: flex;
    font-size: 0.8rem;
    align-items: center;
}

.f-item img{
    height: 1.25rem;
    width: 1.25rem;
}

.f-item p {
    margin: 0;
    font-size: 0.875rem;
    font-weight: 500;
    color: #192057;
}
/* .sec-img{
    height: 1.25rem;
    width: 1.25rem;
} */



/* Responsive Code */

/* For Tablet */

@media only screen and (max-width: 77rem) {
    .contact-left-section{
        padding-top: 20px;
    }

    .contact{
        font-size: 1.125rem;
    }
    .touch{
        font-size: 2.75rem;
    }
    .f-input{
        font-size: 0.75rem;
    }
    .f-item p{
        font-size: 0.6875rem;
    }
    

}


/* For Mobile */

@media only screen and (max-width: 45rem) {
    .contact-right-section {
        margin-top: 35px;
    }
    .contact-left-section {
        padding-top: 0;
    }
    .contact-left-section h6 {
        font-size: 16px;
    }
    .contact-left-section p {
        font-size: 12px;
    }
    .contact-us-body {
        padding: 2.12rem 1.25rem 1.08rem 1.25rem;
    }

    .contact {
        font-size: 1rem;
    }

    .contact-us-container{
        flex-direction: column;
    }

    .contact-us-form {
        width: 80%;
    }

    .map-container {
        position: static;
        width: 100%;
        height: 17.78rem;
    }

    .map-container img {
        width: 100%;
    }

    .touch {
        margin: 0;
        font-size: 1.125rem;
        margin: 2rem 0 1.5rem 0;
    }

    .f-input, .l-input {
        margin-bottom: 0.71rem;
        padding: 0.53rem 0.77rem;
        font-size: 0.5rem;
    }

    .f-input::placeholder {
        font-size: 0.5rem;
    }

    .l-input::placeholder{
        font-size: 0.5rem;
    }

    .contact-submit-button img {
        width: 0.927rem;
        height: 0.927rem;
    }

    .contact-submit-button {
        margin-bottom: 1.6rem;
    }

    .foot-body {
        margin-bottom: 0.94rem;
    }
    
    .foot-items {
        padding: 0.57rem;
        gap: 1.34rem;
    }
    
    .f-item {
        font-size: 0.399rem;
    }

    .f-item p {
        margin: 0;
        font-size: 0.399rem;
    }
    
    .f-item img{
        height: 0.57rem;
        width: 0.57rem;
    }

}


@media only screen and (max-width: 28rem) {
    .contact-us-form {

    }   
}