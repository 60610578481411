.insights {
  /* height: 40rem; */
  box-sizing: border-box;
  background-image: url("../../images/insights_section_bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px 3.75rem;
  min-height: 650px;
}

.insight-top {
  position: relative;
  display: flex;
  justify-content: space-between;
}
.insight-heading {
  /* position: absolute;
  content: url('../../images/underline-white.svg');
  bottom: -10px;
  display: inline-block; */
}
.insights-container {
  width: 100%;
  overflow: hidden;
}

.insights-row {
  display: flex;
  width: 100%;
  justify-content: space-around;
  transition: transform 0.3s ease-in-out;
}

.insight-card {
  flex: 0 0 50%;
}

.insights-row::-webkit-scrollbar {
  display: none;
}

.insight-card img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.insight-card .insight-text {
  margin-left: auto;
  margin-right: auto;
}

.insight-heading {
  color: #e7e3db;
  font-size: 24px;
  font-weight: 500;
  padding-bottom: 20px;
  text-align: left;
  position: relative;
}
.insight-heading img {
  position: absolute;
  left: 0;
  bottom: 6px;
  width: 100%;
}

.insight-text {
  text-align: left;
}
.insights-img-container{
  overflow: hidden;
  height: 325px;
}
.insight-image {
  transition: 0.3s;
  width: 100%;
  height: 100%;
}
.insight-image:hover {
  transform: scale(1.1);
}

.insight-text .insight-text-heading {
  color: #e7e4dc;
  font-size: 24px;
  font-weight: 400;
  line-height: 1;
}

.insight-text .insight-text-text {
  color: #e7e4dc;
  font-size: 14px;
  font-weight: 300;
  line-height: 1;
}

.insight-text .insight-text-date {
  color: #9dcb3b;
  font-size: 18px;
  font-weight: 500;
  line-height: 1;
}
.arroww-container {
  width: 95%;
  display: flex;
  margin: auto;
  /* margin-top:-25px; */
  /* margin-bottom: 40px; */
  justify-content: end;
  align-items: center;
}

.prev-arrow {
  margin-right: 5px;
}
.prev-arrow,
.next-arrow {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.prev-arrow img,
.next-arrow img {
  width: 100%;
  height: 100%;
}
.prev-arrow-image {
  transform: rotate(180deg);
}
.insight-arrow{
  display: none;
}

@media only screen and (min-width: 319px) {
  .insight-card {
    height: 100%;
    min-width: 230px;
    margin-top: 30px;
  }
}

@media only screen and (max-width: 426px) {
  .insights {
    height: auto;
    padding: 20px;
  }

  .insight-heading {
    padding: 0px;
    font-size: 16px;
    font-weight: 500;
  }

  .insights-row {
    overflow-x: scroll;
    justify-content: start;
  }

  .insights-row::-webkit-scrollbar {
    width: 0;
  }

  .insight-card {
    height: 100%;
    min-width: 320px;
    margin-top: 30px;
  }

  .insight-text .insight-text-heading {
    font-size: 12px;
    font-weight: 400;
  }

  .insight-text .insight-text-text {
    font-size: 8px;
    font-weight: 400;
  }

  .insight-text .insight-text-date {
    font-size: 9px;
    font-weight: 500;
  }
}

@media only screen and (max-width: 77rem) {
  .insights-img-container{
    height: 250px;
  }
  .insight-arrow {
    display: block;
  }
  .insight-heading {
    font-size: 1.125rem;
  }
  .insight-text .insight-text-heading {
    font-size: 1.125rem;
  }
  .insight-text .insight-text-text {
    font-size: 0.75rem;
  }
  .insight-text .insight-text-date {
    font-size: 0.8125rem;
  }
  .insights {
    height: auto;
  }

  .insight-card {
    height: 100%;
    /* min-width: 330px; */
  }

  .insight-text .insight-text-heading {
    font-size: 18px;
    font-weight: 400;
  }

  .insight-text .insight-text-text {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .insight-text .insight-text-date {
    font-size: 13px;
    font-weight: 500;
  }
}

@media only screen and (min-width: 1024px) {
  .insights {
    /* removed height for testing */
    /* height: 33rem; */
  }

  .insight-card {
    height: auto;
    min-width: 330px;
  }

  .insight-text .insight-text-heading {
    font-size: 20px;
    font-weight: 400;
  }

  .insight-text .insight-text-text {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .insight-text .insight-text-date {
    font-size: 16px;
    font-weight: 500;
  }
}

@media only screen and (max-width: 50rem) {
  .insights {
   min-height: 480px;
  }
  .insight-heading img{
    bottom: -5px;
  }
}
