.landing-page {
    height: 46.8125rem;
    box-sizing: border-box;
    background-image: url('../images/landing_page_background.webp');
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
}

.landing-page-container {
    justify-content: center;
    color: #E7E4DC;
    font-style: italic;
    padding-top: 9.81rem;
    padding-left: 3.75rem;
}

 .defining {
    font-size: 2.625rem;
    font-weight: 300;
    margin-bottom: 1rem;
}

 .digital-future {
    font-size: 3.875rem;
    font-weight: 600;
    font-style: normal;
    margin-bottom: 1.62rem; 
}

 .digital-transformation {
    color: #FFF;
    font-size: 1.125rem;
    font-style: italic;
    font-weight: 500;
    margin-bottom: 2.81rem;
}

 .learn-more {
    color: #FFF;
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
    text-decoration: none;
    font-style: normal;
}

.progress-bar {
    position: relative;
    width: 95%;
    max-width: 30rem;
    height: 0.25rem;
    background-color: #FFFFFF;
    margin-top: 8px;
}

.progress-bar::before {
    content: '';
    position: relative;
    display: block;
    width: 50%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #9DCB3B;
}



/* Responsive Code */


/* For Tablet */

@media only screen and (max-width: 77rem) {
    
    .defining {
        color: #E7E4DC;
        font-size: 2.125rem;
        font-style: italic;
        font-weight: 300;
        margin-bottom: 0.44rem;
    }
    
    .digital-future {
        color: #E7E4DC;
        font-family: Poppins;
        font-size: 3.25rem;
        font-style: normal;
        font-weight: 600;
        margin-bottom: 0.44rem;
    }

    .digital-transformation, .learn-more {
        color: #FFF;
        font-family: Poppins;
        font-size: 1rem;
        font-style: italic;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 0.44rem;
    }

    .learn-more {
        color: #FFF;
        font-family: Poppins;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 0.27rem;
    }
    .landing-page-container {
        padding: 0;
    }

    .landing-page {
        padding: 0 0 3.09rem 1.25rem;
        display: flex;
        flex-direction: column;
        justify-content: end;
    }
}


/* For Mobile */

@media only screen and (max-width: 45rem) {
    .defining {
        font-size: 1.5rem;
    }
    .landing-page {
        height: 41rem;
    }
    
    .digital-future {
        font-size: 2.125rem;
    }

    .digital-transformation, .learn-more {
        font-size: 0.5625rem;
    }
    
    .landing-page-container {
    }
}