.line{
    width:95%;
    margin:auto;
    height: 2px;
    background-color:#192057;
}
.start-content {
    width: fit-content;
}
.icon-footer{
    margin-bottom: 200px;
    width:100%;
    box-sizing: border-box;
    margin: auto;
    padding: 20px 55px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    bottom: 0;
    background-color: #E7E4DC;
    z-index: 1;
}
.icon-footer .logo-image {
    width: 180px;
}

.icon-container{
    display: flex;
    justify-content: space-around;
}
.link{
    margin-left: 20px;
}

@media screen and (max-width:500px) {
    .link{
        margin-left:5px;
    }
    .icon-footer {
        width: auto;
        padding: 20px 15px;
     }
     .icon-footer .logo-image{
        width:100px;
     }
     .icon-container a {
        display: flex;
        margin-left: 10px;
    }
     .icon-container img {
         width: 20px;
     }
} 