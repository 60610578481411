.header-container {
    background-color: #E7E4DC;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 2;
    box-shadow: 0 2px 7px -2px rgba(0, 0, 0, 0.2);
}
.mobile-nav{
    display: none;
    height: 0;
    transition:all 0.30s ease-in-out;
}
.nav-icon1 {
    width: 24px;
    height: 45px;
    position: relative;
    margin: 50px auto;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
  }
  .nav-icon1 span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #192057;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }

  .nav-icon1 span:nth-child(1) {
    top: 13px;
  }
  
  .nav-icon1 span:nth-child(2) {
    top: 20px;
  }
  
  .nav-icon1 span:nth-child(3) {
    top: 27px;
  }
  
  .nav-icon1.open span:nth-child(1) {
    top: 18px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  
  .nav-icon1.open span:nth-child(2) {
    opacity: 0;
    left: -60px;
  }
  
  .nav-icon1.open span:nth-child(3) {
    top: 18px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }
header {
    background: linear-gradient(90deg, rgba(217, 217, 217, 0.20) 0%, rgba(217, 217, 217, 0.15) 100%);
    height: 6.437rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 3.88rem;
    padding-right: 3.69rem;
    transition: height 0.3s; 
}


.logo{
    display: flex;
    align-items: center;
    flex:1;
    margin-top: 8px;
}
.header-logo{
    width: 220px;
    transition:width 0.8s;
}
header ul {
    list-style: none;
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    padding: 1rem 2rem;
    border-radius: 2rem;
    background-color: #9DCB3B;
    width: fit-content;
    transition: all 0.8s;
}

.header-list-shrink{
    list-style: none;
    display: flex;
    gap: 1.5rem;
    padding: 0.5rem 2rem;
    border-radius: 2rem;
    background-color: #9DCB3B;
    width: fit-content;
    transition: all 0.8s;
}
.globe-icon{
    width: 30px;
}

header li {
    display: inline-block;
    /* padding-bottom:2px; */
    /* background-image: linear-gradient(#0A0D25 0 0);
    background-position: 0 100%;
    background-size: 0% 2px;
    background-repeat: no-repeat;
    transition:
      background-size 0.3s,
      background-position 0s 0.3s; */
      
}
header li a::after{
    position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: '.';
  color: transparent;
  background: #0A0D25;
  height: 2px;
  transition: all .2s;
}
header li a::before{
  transition: all .5s;
}
header li a:hover::after{
    width: 100%;
}

header li a {
    position: relative;
    text-decoration: none;
    color: #0A0D25;
    font-weight: 400;
    cursor: pointer;
    font-size: 1rem;
    padding-bottom: 5px;
    transition: all 0.5s;
}

header ul li:hover {
    background-position: 100% 100%;
    background-size: 100% 2px;
}

header ul li a.active {
    font-weight: 600;
}

header ul li a :active {
    font-weight: 600;
}

.region {
    text-decoration: none;
    color: #9DCB3B;
    font-family: 'poppins';
    font-weight: 300;
    font-size: 1rem;
    display: flex;
    align-items: center;
}

.mobile-navbar-btn {
    display: none;
}
.tiny-header {
    background: linear-gradient(90deg, rgba(217, 217, 217, 0.20) 0%, rgba(217, 217, 217, 0.15) 100%);
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 3.88rem;
    padding-right: 3.69rem;
    transition: height 0.3s; 
}
.tiny-nav-items {
    text-decoration: none;
    color: #0A0D25;
    font-weight: 400;
    cursor: pointer;
    font-size: 13px;
}
.nav-item-shrink {
    text-decoration: none;
    color: #0A0D25;
    font-weight: 400;
    cursor: pointer;
    font-size: 14px;
    transition: all 0.5s;
}
.region-shrink {
    text-decoration: none;
    color: #9DCB3B;
    font-weight: 300;
    font-size: 14px;
    display: flex;
    align-items: center;
}
.header-logo-shrink {
    width: 180px;
    transition: width 0.8s;
}
.region-container{
    display: flex;
    justify-content: flex-end;
    flex: 0.5;
    cursor: pointer;
}

.lang-container{
    width:0;
    opacity: 0;
 }
.show-lang-container{
    width: 7.1875rem;
    height: 3.5rem;
    border-radius: 1.5rem;
    justify-content: center;
    align-items: center;
    display: flex;
    transition:  .5s ease-in-out;
}
.show-lang-container div{
    margin-right: 5px;
}
.locale {
    color: #192057;
}
.selected-locale{
    color: #9DCB3B;
    font-weight: 500;
}



/* Responsive Code */




@media only screen and (max-width: 77rem) {
    .region-container {
        padding-right: 40px;
    }
    .show-lang-container{
        width: 6.1875rem;
    height: 2.5rem;
    }
    .mobile-nav-display{
        display: block;
        width: 100vw;
    height: 100vh;
    background: #E7E4DC;
    position: fixed;
    top: 60px;
    left: 0;
    box-sizing: border-box;
    z-index: 1;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0 1.5rem;
    transition:all 0.30s ease-in-out;
    }
    header {
        height: 5rem;
        padding: 0 1.5rem;
    }
    header li a{
        font-size: 12px;
    }
    .region{
        font-size: 12px;
    }
    header ul{
        padding: 0.5rem 2rem;
    }

    .header-logo {
        /* height: 1.6875rem;
        width: 7.625rem; */
        width: 150px;
    }
    
    /* header ul {
        display: none;
    } */
/*     
    .region {
        display: none;
    } */
    /* .region-shrink{
        display: none;
    } */
    
    /* .mobile-navbar-btn {
        display: flex;
    } */
}

@media only screen and (max-width:54.25rem) {
    .region-container {
        padding-right: 20px;
    }
    .show-lang-container{
        width: 5.1875rem;
        height: 2rem;
    }
    .show-lang-container div {
        margin-right: 5px;
        font-size: 14px;
    }

    header{
        height: 4.5rem;
    }
    .header-logo{
        width: 7.625rem;
    }
    header ul {
        display: none !important;
    } 
    
    .region {
        display: none;
    } 
    .region-shrink{
        display: none;
    }
    
     .mobile-navbar-btn {
        display: flex;
    }
}


