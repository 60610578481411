.learn-more-container {
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
  position: relative;
  background-color: #e7e4dc;
}
.learn-more-title-container {
  position: relative;
  align-self: flex-start
}
.learn-more-title-container img{
  position: absolute;
  width: 100%;
}
.learn-more-header {
  width: 90%;
  margin: auto;
  height: 450px;
}
.learn-more-arrow {
  width: 70px;
  height: 70px;
  flex-shrink: 0;
  position: absolute;
  right: 56px;
  top: 0px;
}
.ai-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.learn-more-content {
  margin: auto;
  width: 69%;
  margin-top: 90px;
  display: flex;
  flex-direction: column;
  align-items: end;
}

.learn-more-title {
  color: #192057;
  align-self: flex-start;
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.learn-more-text-container {
  display: flex;
  width: 680px;
  height: 319px;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
  margin-top: 37px;
}
.learn-more-text {
  color: #192057;
  text-align: justify;
  font-size: 16px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media only screen and (max-width: 45rem) {
  .learn-more-header {
    min-height: 170px;
  }
  .ai-image {
    width: 100%;
    /* height: 20.0625rem; */
    flex-shrink: 0;
  }
  .learn-more-arrow {
    width: 1.4375rem;
    height: 1.4375rem;
    position: absolute;
    right: 6%;
    top: -15px;
  }
  .learn-more-content {
    width: 87%;
    margin: auto;
    margin-top: 50px;
    align-items: start;
  }
  .learn-more-title {
    font-size: 1.125rem;
  }
  .learn-more-text-container {
    width: 100%;
    height: 16.125rem;
    margin-top: 25px;
    justify-content: space-between;
  }
  .learn-more-text {
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 720px) and (max-width: 1100px) {
  .ai-image {
    width: 100%;
  }
  .learn-more-arrow {
    width: 2.4375rem;
    height: 2.4375rem;
    position: absolute;
    right: 6%;
    top: 0px;
  }
  .learn-more-content {
    width: 95%;
    margin: auto;
    margin-top: 50px;
  }
  .learn-more-title {
    font-size: 1.8125rem;
    text-align: center;
  }
  .learn-more-text-container {
    width: 35.625rem;
    height: 15.75rem;
    margin-top: 25px;
    /* align-self: end; */
    padding-left: 20px;
    justify-content: space-between;
  }
  .learn-more-text {
    font-size: 0.75rem;
  }
}
