.modal-container {
    /* width: 100vw;
    height: 100vh;
    background: #E7E4DC;
    position: fixed;
    top: 60px;
    left: 0;
    box-sizing: border-box;
    z-index: 1;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0 1.5rem; */
}
.hide-modal{
    /* display: none; */
    
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 3.625rem;
}

.modal-header .navbarModal-logo {
    width: 7.625rem;
    height: 1.6875;
}

.navbar-close-button {
    background-color: transparent;
    border: none;
    position: relative;
    top: 0.3rem;
    left: 0.4rem;
}

.navbar-close-button img{
    width: 3.5rem;
    height: 3.5rem;
}

.navbar-hor-line {
    height: 0.15rem;
    border: 0;
    background-color: #192057;
    width: 100%;
}

.full {
    transform: scaleX(1.5);
}


.navbar-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0;
}


.navbar-item-text {
    text-decoration: none;
    color: #192057;
    font-size: 1.5rem;
    font-weight: 500;
}

.navitem-button img {
    width: 1.5625rem;
    height: 1.5625rem;
}

.navitem-button {
    border: none;
    background-color: transparent;
}

/* Responsive */

/* For Mobile */

@media only screen and (max-width: 55rem) {
    .navbar-item-text {

    }
}