@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
.main-container {

  box-sizing: border-box;
  background-image: url("../images/career.webp");
  background-repeat: no-repeat;
  padding: 1.5rem 3.65rem;
  background-size: cover;
}
.career-heading-container{
  position: relative;
}
.career-heading-container img{
  position: absolute;
  width: 100%;
  bottom: 8px
}
.main-section {
  display: flex;
  font-family: Poppins;
}
.rightarrowsection {
  display: none;
}
.top-section {
  display: flex;
  color: #e7e4dc;
}
.left-section {
  width: 60%;
  text-align: justify;
  color: #e7e4dc;
}
.career-button{
  padding: 10px 24px;
  font-family: Poppins;
  border-radius: 18px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  background-color: #9DCB3B;
  color: #192057;
}
.career-heading,
.apply-heading {
  font-size: 1.5rem;
  font-weight: 500;
}
.career-sub-section-heading {
  font-size: 3.875rem;
font-style: normal;
font-weight: 500;
}
.mail {
  border-radius: 1.875rem;
  border: 1px solid white;
  box-sizing: border-box;
  height: 3.6875rem;
  width: 31.875rem;
  flex-shrink: 0;
  font-size: 1.25rem;
  font-family: Poppins;
  color: #e7e4dc;
  padding-left: 2rem;
  background: transparent;
}
input::placeholder {
  color: #E7E4DC;
  font-size: 1rem;
  font-family: Poppins;
}
.rightarrow {
  display: block;
}
.right-section {
  margin-top: 1.25rem;
  width: 40%;
  color: #e7e4dc;
}
.right-heading {
  text-align: end;
  font-weight: 500;
  margin-bottom: 18px;
}
.right-content {
  text-align: end;
  font-size: 1.5rem;
  line-height: 3.5625rem;
}
.right-content li:hover{
  transition: .4s ease;
  font-size: 1.7rem;
  color:#9dcb3b;
}
.attach {
  display: flex;
  align-items: center;
}
.para-content {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 44.75rem;
  height: 6.125rem;
  margin-top: -3.125rem;
  color: #E7E4DC;
text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
font-size: 1rem;
font-style: normal;
line-height: normal;
  text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  font-weight: 500;
}
.attach .symbolimg {
  width: 30px;
  height: 30px;
}
.button {
  display: flex;
  align-items: center;
  width: 13.125rem;
  height: 3.6875rem;
  flex-shrink: 0;
  padding-left: 1.25rem;
  font-family: poppins;
  font-size: 1rem;
  margin: 20px 2px;
  cursor: pointer;
  color: #192057;
  margin-right: 1.25rem;
  border-radius: 1.625rem;
  border: 1px solid #192057;
  background: #d9d9d9;
}
.apply-part {
  display: flex;
  flex-direction: column;
  margin-top: 1.625rem;
}
.apply-part p {
  font-size: 22px;
}
.apply-part span {
  font-weight: 700;
}
.arrowImage {
  width: 30px;
  height: 30px;
}
.green-text {
  color: #9dcb3b;
}
.right-heading {
  font-size: 42px;
}
.vectorline {
  border: 1px solid #e7e4dc;
  margin: auto;
  margin: 1.30rem 0rem
}
.bottom-content {
  width: 85%;
  color: #e7e4dc;
  font-size: 2.5rem;
  font-weight: 500;
  font-family: Poppins;
  text-align: start;
}
.bottom-content {
  font-size: 22px;
}
@media screen and (max-width: 77rem) {
  .apply-part p {
    font-size: 16px;
  }
  .career-button {
    padding: 6px 18px;
    font-size: 10px
  }
  .main-container {
    padding: 0.8rem 1.5rem;
  }

  .top-section {
    justify-content: space-between;
    align-items: center;
  }
  .rightarrowsection {
    display: block;
  }
  .rightarrowsection img {
    width: 17px;
    height: 17px;
  }
  .left-section {
    width: 50%;
   
  }
  .career-heading {
    font-weight: 500;
    font-size: 1.25rem;
    margin-top: 0px;
    line-height: normal;
    font-style: normal;
  }
  .career-sub-section-heading {
    font-size:2.75rem;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 50px;
    line-height: 143.5%;
  }
  .attach .symbolimg {
    width: 11.21px;
    height: 11.21px;
    margin-right: 5px;
  }
  .para-content {
    width: 30rem;
    height: 5rem;
    text-shadow: 0px 1.8683751821517944px 1.8683751821517944px 0px
      rgba(0, 0, 0, 0.25);
   font-size: 0.75rem;
    font-family: Poppins;
    text-align: left;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    top: 8.0625rem;
    margin-right: 1.25rem;
  }
  .apply-part {
    margin-top: 0.0625rem;
  }
  .apply-heading {
    color: #e7e4dc;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .mail {
    border-radius: 15px;
    border: 0.467px solid #e7e4dc;
    height: 30px;
    font-size: 0.75rem;
    width: 250px;
    padding-left: 1.2rem;
  }
  input::placeholder {
    padding-left: 0px;
    font-size: 8.47px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .button {
    width: 120px;
    height: 30px;
    padding-left: 15px;
    font-size: 9px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right: 10px;
    border-radius: 15px;
    border: 0.467px solid #192057;
  }
  .apply-part .arrowimage {
    width: 14.013px;
    height: 14.013px;
  }
  .right-section {
    margin-top: 4.25rem;
    width: 50%;
  }
  .right-heading {
    color: #e7e4dc;
    font-size: 1.875rem;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .right-content {
    color: #e7e4dc;
    text-align: right;
    font-size: 1.125rem;
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    line-height: 43px;
  }
  .right-content li:hover{
    font-size: 1.4rem;
  }
  .bottom-content {
    width: 95%;
    flex-direction: column;

    text-align: left;
    font-size: 2.0rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

  }
}
@media screen and (max-width: 45rem) {
  .apply-part {
    flex: 2;
  }
  .apply-part p {
    font-size: 11px;
  }

  .attach {
    flex-direction: column;
    align-items: flex-start;
  }
  .attach .button{
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .career-button {
    padding: 5px 16px;
    font-size: 8px;
}
  .main-container{
    padding: 1.8rem 1.25rem;
  }

  .left-section {
    width: 50%;
    display: flex;
    flex-direction: column;
  }
  .left-top-section{
    flex:3
  }
  .career-heading {
    font-weight: 500;
    font-size: 1rem;
    margin-top: 0px;
    line-height: normal;
    font-style: normal;
  }
 
  
  .career-sub-section-heading {
    font-size: 18px;
  }
  .para-content {
    width: 20.75rem;
    height: 3.3125rem;
    font-size: 9px;
  }
  .mail
  {
    border-radius: 12.144px;
    height: 28px;
    width: 159px;
    padding-left: 0.6rem;
  }

  input::placeholder {
  font-size: 7px;
  }
  .button {
    width: 106px;
    height: 28px;
    padding-left: 10px;
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    border-radius: 12.144px;
  }
  .right-section {
    margin-top: 8.25rem;
  }
  .right-heading {
    font-size: 11px;
    font-weight: 600;
  }
  .right-content {
    font-size: 10px;
    font-weight: 500;
    line-height: 23.6px;
  }
  .right-content li:hover {
    font-size: 12px;
  }
  
  .bottom-content {
    text-align: start;
    font-size: 1rem;
  }
  .vectorline{
    bottom: 13.5rem
  }
}

/* @media screen and (max-width: 26rem) {
  .para-content {
    width: 16rem;
    height: 3.3125rem;
    font-size: 7px;
  }
  ::placeholder { 
    font-size: 6px;
  } 
  .bottom-content {
    width: 300px;
    
  }
} */




.custom-file-input {
  cursor: pointer;
}

.custom-file-input input[type="file"] {
text-align: center;
  cursor: pointer;
}

.custom-file-input label {
  cursor: pointer;
}

#pdfInput {
  display: none; /* Hide the actual file input */
}

.pdfInputLabel {
  display: flex;
}

