
.container {
  display: flex;
  flex-direction: column;
  color: #192057;
  font-family: Poppins;
  background-color: #e7e4dc;
  justify-content: flex-start;
}
.title-container-whoweare{
  position: relative;
}
.title-container-whoweare img{
  position: absolute;
  width: 100%;
  bottom: 0;
}
.heading {
  display:flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 3.60663rem;
  width: 92%;
  margin: auto;
  padding: 35px;

}
.heading-arrow{
  display: none;
}
.small-arrow{
  display:none;
}
.small-arrow.mobile{
  display:inline-flex;
  width: 1.0625rem;
  height: 1.0625rem;
  flex-shrink: 0;
}
.content {
  display: flex;
  width: 92%;
  margin: auto;
  padding-bottom: 50px;
  font-size:90rem;
}

.description {
  font-size: 2rem;
  font-weight: 500;
  text-align: start;
  line-height: 3.30663rem;
}
.title-container{
  position: relative;
}

.arrow {
  width: 11.75rem;
  height: 11.75rem;
  padding-top: 12px;
  margin-left: auto;
  display: inline-flex;
}

.line-break {
  background-color: #192057;
  width: 93%;
  height: 2px;
  margin: auto;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 86%;
  margin: auto;
  margin-top:15px;
  margin-bottom: 70px;
}

.card {
  display: flex;
  flex-direction: column;
  width: 44%;
  margin-top: 45px;
  height: auto;
}

.card-image{
  overflow: hidden;
  height:325px;
}
.image{
  width:100%;
  transition: 0.3s;
  
}
.image:hover{
  transform: scale(1.1);
}
.card-title {
  font-size: 1.5rem;
  font-weight: 600;
  word-wrap: break-word;
  margin-top: 5px;
}

.card-description {
  font-size: 1rem;
  margin-top: 5px;
  /* overflow: hidden; */
}

.card-description > a {
  color: #9dcb3b;
  text-decoration: none;
}

.slides {
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
}

.card.mobile {
  scroll-snap-align: start;
}

.whoWeAre-arrow-container {
  width:75%;
  display: flex;
  margin: auto;
  margin-top:-20px;
  margin-bottom: 40px;
  justify-content: end;
  align-items: center;
}

.prev-arrow{
  margin-right: 5px;
}
.prev-arrow,
.next-arrow {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.prev-arrow img,
.next-arrow img {
  width: 100%;
  height: 100%;
}
.prev-arrow-image{
  transform: rotate(180deg);
}

@media only  screen and (max-width:767px){
  .arrow{
    display: none;
  }
  .heading{
    font-size: 1rem;
    padding: 20px;
  }
  .content{
    padding-bottom: 30px;
  }
  .heading-arrow{
    display: inline-flex;
  }
  .description{
    width:100%;
    font-size: 1rem;
    line-height: 1.9rem;
   }
  .card{
    width: 100%;
  }
  
  .card-title{
    font-size: 1.3rem;
  }
  .card-description{
    font-size: 0.9rem;
  }
  .image{
    flex-shrink: 2;
    width: 100% !important;
  }
  .whoWeAre-arrow-container{
    width:85%;
    margin-top: 0px;
    margin-bottom: 0px;
  }
}
@media only screen and (max-width:450px){
  .card-image{
    height: 210px;
  }
  .heading{
    width: auto;
    margin: 0;
    
  }
}
@media only screen and (min-width:767px) and (max-width:77rem){
  .heading{
    width:94%;
    padding-left: 26px;
  }
  .heading div{
    color: #192057;
    font-family: Poppins;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 2.5515rem;
  }
  .description{
    color: #192057;
    font-family: Poppins;
    font-size: 1.8125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 2.5515rem;
  }
  .arrow{
    height: 7.75rem;
  }
  .card-title{
    font-size: 1.125rem;
  }
  .card-description {
    font-size: 0.75rem;
  }
  .content{
    width: 94%;
  }
  .card-container{
    width: 94%;
    margin: auto;
    margin-bottom: 0px;
  }
  .card{
    width: 45%;
  }
  .image{
    width:100% !important ;
    
  }
  .line-break{
    width:94%;
  }
  .whoWeAre-arrow-container{
    width:94%;
    margin-top: 0px;
    margin-bottom: 5px;
  }
}